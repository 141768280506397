import {
  ActionDefinition,
  ConnectionBuilder,
} from '../utils/manifest-definitions';
import {Translations} from '../utils/translations';
import {getCurrentVersion} from '../utils/ci-actions';

export class ConnectedComponentModel {
  readonly connection: any;
  readonly role: string;
  readonly labelKey: string;

  constructor(role, connection, labelKey?: string) {
    this.connection = connection;
    this.role = role;
    this.labelKey = labelKey;
  }

  get shouldTranslateLabel() {
    return !!this.labelKey;
  }
}

export interface BaseComponentModelProps {
  nickname: string;
  label: string;
  mainAction1: ActionDefinition;
  mainAction2: ActionDefinition;
  helpId: string;
  connectedComponents?: ConnectedComponentModel[];
  isButtonWidget?: boolean;
  linkedStyles?: string[][];
}

export class BaseComponentModel {
  readonly nickname: string;
  readonly label: string;
  readonly mainAction1: ActionDefinition;
  readonly mainAction2: ActionDefinition;
  readonly helpId: string;
  readonly isButtonWidget: boolean;
  readonly linkedStyles: string[][];
  connectedComponents?: ConnectedComponentModel[];

  constructor({nickname, label, mainAction1, mainAction2, helpId, connectedComponents, isButtonWidget, linkedStyles}: BaseComponentModelProps) {
    this.nickname = nickname;
    this.label = label;
    this.mainAction1 = mainAction1;
    this.mainAction2 = mainAction2;
    this.helpId = helpId;
    this.isButtonWidget = isButtonWidget;
    this.connectedComponents = connectedComponents;
    this.linkedStyles = linkedStyles || [];
  }
}

function replaceHtmlInnerText(html, newText) {
  const end = html.substring(html.indexOf('</'));
  if (end === -1) {
    return newText;
  }
  const begining = html.substring(0, html.substring(0, html.indexOf('</')).lastIndexOf('>') + 1);
  return begining + newText + end;
}

export class BaseEditorComponent {
  readonly baseComponentModel: any;
  readonly editorSdk: any;
  readonly appToken: any;

  constructor(editorSdk, appToken, componentModel: BaseComponentModel) {
    this.editorSdk = editorSdk;
    this.appToken = appToken;
    this.baseComponentModel = componentModel;
  }

  private async getConnectedComponentRef(componentRef, role) {
    const children = await this.editorSdk.components.getChildren(this.appToken, {componentRef});
    const controllerRef = children[0];
    const connectedComponents = await this.editorSdk.document.controllers.listControllerConnections(this.appToken, {controllerRef});
    const component = connectedComponents.find(component => component.connection.role === role);
    if (component) {
      return component.componentRef;
    }
    return null;
  }

  private getTranslatableComponents() {
    if(this.baseComponentModel.connectedComponents?.length > 0) {
      return this.baseComponentModel.connectedComponents.filter(component => component.shouldTranslateLabel);
    }
    return [];
  }

  async translateLabels(controllerRef) {
    const siteLanguageCode = await this.editorSdk.document.info.getRegionalLanguage(this.appToken);
    const siteTranslation = new Translations();
    await siteTranslation.init(siteLanguageCode, await getCurrentVersion(this.editorSdk, this.appToken));
    const translatableComponents = this.getTranslatableComponents();
    const updateTranslationPromises = [];
    translatableComponents.forEach((component) => {
      updateTranslationPromises.push((async () => {
        const componentRef = await this.getConnectedComponentRef(controllerRef, component.role);
        const currentData = await this.editorSdk.components.data.get(this.appToken, {componentRef});
        await this.editorSdk.components.data.update(this.appToken, {
          componentRef, data: {
            text: replaceHtmlInnerText(currentData.text, siteTranslation.t(component.labelKey)),
          }
        });
      })());
    });
    return Promise.all(updateTranslationPromises);
  }

  getLinkedStyles(styleRole: string) {
    return this.baseComponentModel.linkedStyles.find(linkedStylesGroup => linkedStylesGroup.find((linkedStyleRole) => linkedStyleRole === styleRole)).filter((linkedStyleRole) => linkedStyleRole !== styleRole);
  }

  async getComponentRefRole(componentRef) {
    const data = await this.editorSdk.components.get(this.appToken,
      {
        componentRefs: componentRef,
        properties:['connections']
      });
    const role = data[0]?.connections[0]?.role;
    return role;
  }

  async handleStylesChange(controllerRef, styledComponentRef) {
    const styledComponentRole = await this.getComponentRefRole(styledComponentRef);
    const sourceStyle = await this.editorSdk.components.style.get(this.appToken, {componentRef: styledComponentRef});
    const linkedStyles = this.getLinkedStyles(styledComponentRole)
    const updateStylesPromises = [];
    linkedStyles.forEach((linkedStyleRole) => {
      updateStylesPromises.push((async () => {
        const targetComponentRef = await this.getConnectedComponentRef(controllerRef, linkedStyleRole);
        await this.editorSdk.components.style.updateFull(this.appToken, {componentRef: targetComponentRef, style: sourceStyle});
      })());
    });
    return Promise.all(updateStylesPromises);
  }

  get connection() {
    let baseConnectionBuilder = new ConnectionBuilder()
      .withNickname(this.baseComponentModel.nickname)
      .withLabel(this.baseComponentModel.label)
      .withMainAction1(this.baseComponentModel.mainAction1)
      .withMainAction2(this.baseComponentModel.mainAction2)
      .withHelpId(this.baseComponentModel.helpId)

    let connections = {};
    if(this.baseComponentModel.connectedComponents?.length > 0) {
      this.baseComponentModel.connectedComponents.forEach(component => {
        connections[component.role] = component.connection;
      });
      baseConnectionBuilder.withConnections(connections);
    }
    if (this.baseComponentModel.isButtonWidget) {
      baseConnectionBuilder
        .withoutFirstTimeModal()
        .withDisabledLinkAbility()
        .withDisabledRotatability()
    }
    return baseConnectionBuilder.build();
  }
}
